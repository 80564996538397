@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply text-base text-blue-500 hover:underline hover:text-blue-600;
  }

  h1 {
    @apply text-3xl sm:text-4xl md:text-5xl font-extrabold leading-none;
  }

  h2 {
    @apply text-2xl font-extrabold text-gray-700;
  }

  h3 {
    @apply text-xl font-bold text-gray-700;
  }

  /* Chrome, Safari, Edge, Opera */
  input.hide-spinner::-webkit-outer-spin-button,
  input.hide-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input.hide-spinner[type="number"] {
    -moz-appearance: textfield;
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center;
  }

  .btn-brand {
    @apply btn py-2 px-4 rounded shadow-sm text-lg text-gray-700 hover:text-gray-700 font-bold bg-brand hover:bg-brand-400 focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  a.btn-brand {
    @apply no-underline  hover:text-gray-700;
  }

  .btn-brand.btn-loading,
  .btn-brand:disabled {
    @apply bg-brand-50 text-gray-500 cursor-not-allowed;
  }

  .btn-muted {
    @apply btn py-2 px-4 rounded shadow-sm text-lg text-gray-600 font-medium bg-slate-200 hover:bg-slate-300 hover:text-gray-700 focus:outline-none;
  }

  .btn-muted.btn-loading,
  .btn-muted:disabled {
    @apply bg-gray-100 text-gray-400 cursor-not-allowed;
  }

  .btn-link {
    @apply py-2 px-4 bg-transparent border-transparent focus:outline-none text-brandSecondary-100 hover:text-brandSecondary-200 hover:underline;
  }

  .text-link {
    @apply text-blue-500 hover:underline hover:text-blue-600;
  }

  .btn-link-brand {
    @apply py-2 px-4 bg-transparent border-transparent focus:outline-none text-brand hover:text-brand-300;
  }

  .btn-link-lg {
    @apply btn-link text-lg;
  }

  .input {
    @apply block w-full py-3 px-4 border bg-white border-gray-200 rounded shadow-sm focus:ring-brandSecondary-100 focus:ring-1 focus:border-brandSecondary-100;
  }

  .input:focus-visible {
    outline: none;
  }

  .btn-sm {
    @apply btn py-1 px-3 text-sm focus:ring-1 focus:ring-offset-1 font-semibold;
  }

  .terms-container a {
    @apply text-blue-500 underline hover:text-blue-600;
  }

  .terms-container p {
    @apply mb-4;
  }

  .terms-container ol {
    @apply mb-4;
  }

  .terms-container ol li {
    @apply mb-2;
  }

  .terms-container ol li ol {
    @apply ml-0 mt-2;
  }

  .terms-minimised-sm {
    @apply max-h-32 relative overflow-hidden;
  }

  .terms-minimised-lg {
    @apply max-h-64 relative overflow-hidden;
  }

  .terms-expanded .terms-fader {
    @apply hidden;
  }

  .subnumbering ol {
    counter-reset: item;
  }

  .subnumbering li {
    display: block;
  }

  .subnumbering li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
  }
}
