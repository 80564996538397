.userMenuToggle {
  display: none;
}

.userMenuToggle:not(checked) ~ .userMenu {
  display: none;
}

.userMenuToggle:checked ~ .userMenu {
  display: block;
}

.userMenuToggle:checked + label {
  @apply bg-brand;
}

.userMenu a {
  @apply text-gray-700;
}
